export const CONFIG_WITHOUT_TOKEN = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
}
export const API_URL = 'https://apis.joinearthly.com';
//export const API_URL = 'http://64.225.117.112:8083';

export const FACEBOOK_ID = "2863811947228092";

export const GOOGLE_LOGIN_ID = "411411413067-v0mfmahue7pjbqdnnm9pic42cfiap3p5.apps.googleusercontent.com";
