import React from 'react';
import { Link } from "gatsby"
import {Container, Navbar,Nav} from 'react-bootstrap';
import footer_logo from '../images/footer_logo.png';
import './header_footer_css.css';
import facebook_icon  from '../images/facebook.png';
import instagarm_icon  from '../images/instagram.png';
const footer = () => (
  <>
  <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
    <Navbar.Brand>
      <Link to="/">
        <img style={{marginBottom:"0px"}} src={footer_logo} alt="footer_logo"/>
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <div className="row w-100">
        <div className="col-md-4 footer_link" style={{textAlign:'center'}}><Nav.Link eventKey="1" href="/privacy_policy">Privacy Policy</Nav.Link></div>
        <div className="col-md-4 footer_link" style={{textAlign:'center'}}><Nav.Link  eventKey="2" href="/terms_of_use">Terms of Use</Nav.Link></div>
        <div className="col-md-4 footer_link" style={{textAlign:'center'}}>
          <ul className="list-inline" style={{marginBottom:"0px"}}>
            <li className="list-inline-item" style={{marginBottom:"0px",marginRight:"15px"}}>
              <a href="#">
                <img style={{marginBottom:"0px",height:"32px"}} src={facebook_icon} alt="footer_logo"/>
              </a>
            </li>
            <li className="list-inline-item" style={{marginBottom:"0px"}}>
              <a href="#">
                <img style={{marginBottom:"0px",height:"32px"}} src={instagarm_icon} alt="instagram"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Navbar.Collapse>
  </Navbar>

</>


)

export default footer;
