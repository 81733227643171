import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useEffect} from "react"
import {Navbar,Nav} from 'react-bootstrap';

import './header_footer_css.css';

import earthly_logo from "../images/earthly_logo.png";
import user_icon from '../images/user.png';
import user_icon_login from '../images/user_login_icon.svg';



//let user_is_login = localStorage.getItem("earthly_web_token");

const Header = ({ siteTitle,openModal }) => {

  const [key, setKey] = React.useState(undefined);
  const [userimg,setUserimg] = React.useState(null);
  const [installurl,setInstallurl] = React.useState("");

  useEffect(() => {
    setKey(localStorage.getItem('earthly_web_token'))
    let users_detail = localStorage.getItem("earthly_login_user");
    if(users_detail !== undefined){
        let users_data = JSON.parse(users_detail);
        if(users_data !== undefined && users_data !== null){
            if(users_data.photo !== null && users_data.photo !== undefined){
                    setUserimg(users_data.photo);
            }
        }


    }
    if(navigator.userAgent.indexOf("Chrome") != -1 ){
        setInstallurl("https://chrome.google.com/webstore/detail/earthly/kblhgfjcdibpokglmdpopbkbiaknakii");
    }else{
        setInstallurl("https://apps.apple.com/us/app/earthly-shopping-assistant/id1577359249?mt=12");
    }



  }, [])

return (
  

  <Navbar bg="white" expand="lg" sticky="top" className="shadow_header bg-white rounded header">
    <Navbar.Brand>
      <Link to="/">
        <img src={earthly_logo} alt="Logo" style={{marginBottom:"5px",height:"31px"}} />
        <span className="brand_name">Earthly</span>
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <div className="row w-100">
        <div className="col-md-5" style={{textAlign:'center'}}></div>
        <div className="col-md-7 header_link" style={{textAlign:'end'}}>
          <ul className="list-inline" style={{margin:"0px"}}>
              <li className="list-inline-item mr-4" style={{marginBottom:"0px"}}>
{<a href={installurl} className="add_to_browser_btn btn btn-md" role="button">Add to Browser</a>}
              </li>
              <li className="list-inline-item mr-4" style={{marginBottom:"0px"}}>

                <Link to= '/mission'  activeClassName="active">Mission</Link>
              </li>
              <li className="list-inline-item mr-4" style={{marginBottom:"0px"}}>
                <Link to= '/method'  activeClassName="active">FAQ</Link>
              </li>
              <li className="list-inline-item" style={{marginBottom:"0px"}}>
                {
                  key !== "" && key !== undefined && key !== null ?

                <Link to= '/profile'  activeClassName="active"><img className="user_icon_header_with_login" src={userimg !== undefined && userimg !== null ? userimg:user_icon_login} alt="User icon"/></Link>
                :
                <Link to= '/login'  activeClassName="active"><img className="user_icon_header" src={user_icon} alt="User icon"/>Login</Link>
              }
              </li>
            { /*
              <li className="list-inline-item">
                <Link to="/login" className="header_link_color">
                  <img className="user_icon_header" src={user_icon} alt="User icon"/>
                    Login
                </Link>
              </li>
            */}
          </ul>
        </div>
      </div>
    </Navbar.Collapse>
  </Navbar>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
